import _ from 'lodash'
import _getOr from 'lodash/fp/getOr'
import _mapValues from 'lodash/fp/mapValues'
import {
    resolvePayload,
    renderToStaticMarkup,
    getTitle,
    setTitle,
    getDescription,
    getCanonical,
    setDescription,
    setCanonical,
    addSchema,
    setIndexable,
    getMetaTags,
    filterPageLevelTags,
    resolveWithPatterns,
    getAdapter,
    getPatternBlob,
    ITEM_TYPES,
    getSchemas,
    getLinks,
    removeTagsByType,
    buildOgImageFullUrl,
    getValueByIdentifier,
    EXTERNAL_IDENTIFIERS
} from '@wix/advanced-seo-utils/renderer'

import {
    convertDynamicPageModel,
    convertSeoModel,
    convertTpaModel,
    convertPlatformMetaTagsModel,
    convertSiteLevelMetaTags,
    convertPlatformLinksModel
} from '@wix/advanced-seo-utils/converters'
import * as mediaItemUtils from 'santa-platform-utils/dist/cjs/mediaItemUtils/mediaItemUtils'
import imageClientSDK from 'image-client-api/dist/imageClientSDK'

import {withActions} from 'carmi-host-extensions'
import {SOURCES} from './seo.sources'
import {OVERRIDE_IDENTIFIERS, HEAD_OPTION_IDENTIFIERS} from './seo.identifiers'
import {tpaHandlersFunctionLibrary} from 'bolt-tpa/src/aspects/tpaHandlers/tpaHandlers'
import {FETCH_TPA_DATA_STATUS} from './utils'
import {IDENTIFIER_TO_TAG_TYPE} from './seo.tagTypes'

// get this from aspectNames once we are there
export const name = 'SeoAspect'

export const defaultModel = {
    overrides: {
        [SOURCES.TPA]: {
            [OVERRIDE_IDENTIFIERS.TITLE]: {},
            [OVERRIDE_IDENTIFIERS.DESCRIPTION]: {},
            [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: {},
            [OVERRIDE_IDENTIFIERS.CANONICAL]: {},
            [OVERRIDE_IDENTIFIERS.ROBOTS]: {},
            [OVERRIDE_IDENTIFIERS.META_TAGS]: {},
            [OVERRIDE_IDENTIFIERS.LINKS]: {}
        },
        [SOURCES.UNSPECIFIED]: {
            [OVERRIDE_IDENTIFIERS.TITLE]: {},
            [OVERRIDE_IDENTIFIERS.DESCRIPTION]: {},
            [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: {},
            [OVERRIDE_IDENTIFIERS.CANONICAL]: {},
            [OVERRIDE_IDENTIFIERS.ROBOTS]: {},
            [OVERRIDE_IDENTIFIERS.META_TAGS]: {},
            [OVERRIDE_IDENTIFIERS.LINKS]: {}
        }
    },
    payload: {},
    forceOverride: false,
    siteMetadataChangedRegisteredComps: {},
    tpaData: {},
    fetchTpaDataStatus: FETCH_TPA_DATA_STATUS.INITIAL,
    seoReturnedStatusCode: null,
    debugInfo: {},
    ssrData: {},
    asNewPage: {}
}
const handleDebug = (pushToDebugInfo, {isDebugSeoEnabled, path} = {}, debugData) => {
    if (isDebugSeoEnabled) {
        pushToDebugInfo(path, {timestamp: Date.now(), ...debugData})
    }
}
function resolvePayloadWithDebug({pushToDebugInfo}, payload, debugProps) {
    const result = resolvePayload(payload)
    handleDebug(pushToDebugInfo, debugProps, {
        method: 'resolvePayload',
        arguments: {payload},
        mappedArguments: {
            payload: mapPayload(payload)
        },
        result
    })
    return result
}
export const functionLibrary = {
    getAdapter,
    getPatternBlob,
    getTitle,
    getDescription,
    getCanonical,
    getMetaTags,
    getSchemas,
    getLinks,
    removeTagsByType,
    getTagsMarkup: tags => renderToStaticMarkup(tags),
    resolveWithPatterns: withActions(({pushToDebugInfo}, payload, adaptedContext, debugProps) => {
        const shouldRemoveBlackListedTags = true
        const result = resolveWithPatterns(payload, adaptedContext, shouldRemoveBlackListedTags)
        handleDebug(pushToDebugInfo, debugProps, {
            method: 'resolveWithPatterns',
            arguments: [payload, adaptedContext, shouldRemoveBlackListedTags],
            mappedArguments: {
                resolvedData: payload,
                context: adaptedContext
            },
            result
        })
        return result
    }),
    resolvePayload: withActions(resolvePayloadWithDebug),
    convertSeoModel,
    convertDynamicPageModel,
    extractExternalRouterPageData: withActions((actions, payload, currentPageId, debugProps) => {
        if (!payload) {
            return {}
        }

        Object.keys(payload).forEach(key => {
            const value = payload[key]
            const setterProps = [currentPageId, debugProps, {value, source: SOURCES.CORVID}]

            const headOption = HEAD_OPTION_IDENTIFIERS[key]
            if (_.get(headOption, 'type') === _.get(value, 'constructor.name')) {
                set(headOption.identifier)(actions, ...setterProps)
            }
        })
        return payload
    }),
    convertTpaModel,
    convertSiteLevelMetaTags,
    renderToStaticMarkup: withActions(({pushToDebugInfo}, tags, debugProps) => {
        const result = renderToStaticMarkup(tags)
        handleDebug(pushToDebugInfo, debugProps, {
            method: 'renderToStaticMarkup',
            arguments: tags,
            result
        })
        return result
    }),
    filterPageLevelTags,
    setRunTimePageTitle: withActions(set(OVERRIDE_IDENTIFIERS.TITLE)),
    setRunTimePageDescription: withActions(set(OVERRIDE_IDENTIFIERS.DESCRIPTION)),
    setRunTimeSchema: withActions(_setRunTimeSchema),
    setRunTimeSchemaFromFetcher: withActions(({setOverride}, overrides, currentPageId, debugProps, schema) => {
        _setRunTimeSchema({setOverride}, overrides, currentPageId, debugProps, {value: schema, source: SOURCES.TPA})
    }),
    setRunTimeCanonical: withActions(set(OVERRIDE_IDENTIFIERS.CANONICAL)),
    setRunTimeIndexable: withActions(set(OVERRIDE_IDENTIFIERS.ROBOTS)),
    setRunTimeMetaTags: withActions(set(OVERRIDE_IDENTIFIERS.META_TAGS)),
    setRunTimeLinks: withActions(set(OVERRIDE_IDENTIFIERS.LINKS)),
    setWindowTitle: (windowObject, title) => {
        if (_.get(windowObject, 'document')) {
            windowObject.document.title = title
        }
        return title
    },
    setRunTimeSeoTags: withActions(({setAsNewPage, setSeoSsrData, setPayload}, pageId, isInSsr, requestData = {}) => {
        if (_.isEmpty(requestData)) {
            requestData = {itemType: ITEM_TYPES.STATIC_PAGE_V2, itemData: {}, seoData: {}, asNewPage: false}
        }
        const {itemType, itemData, seoData, asNewPage = true} = requestData
        setPayload(pageId, {itemType, itemData, seoData})
        setAsNewPage(pageId, asNewPage)
        if (!isInSsr) {
            setSeoSsrData({})
        }
        return {itemType, itemData, seoData}
    }),

    resetRunTimeSeoTags: withActions(({setAsNewPage, setSeoSsrData, setPayload}, pageId, isInSsr) => {
        setAsNewPage(pageId, false)
        setPayload(pageId, {itemType: ITEM_TYPES.STATIC_PAGE_V2, itemData: {}, seoData: {}})
        if (!isInSsr) {
            setSeoSsrData({})
        }
    }),

    transformWixImageToPublicURL,
    createOverrideSchema: (currentPageId, identifiers) =>
        _.reduce(identifiers, (schema, pageIds, identifier) => {
            const value = pageIds[currentPageId]
            if (value === undefined) {
                return schema
            }
            return ({
                [OVERRIDE_IDENTIFIERS.TITLE]: () => setTitle(schema, value),
                [OVERRIDE_IDENTIFIERS.DESCRIPTION]: () => setDescription(schema, value),
                [OVERRIDE_IDENTIFIERS.STRUCTURED_DATA]: () => value.reduce((accSchema, currJsonLd) => addSchema(accSchema, currJsonLd), schema),
                [OVERRIDE_IDENTIFIERS.CANONICAL]: () => setCanonical(schema, value),
                [OVERRIDE_IDENTIFIERS.ROBOTS]: () => setIndexable(schema, value),
                [OVERRIDE_IDENTIFIERS.META_TAGS]: () => resolvePayload([schema, resolvePlatformMetaTagsModel(value)]),
                [OVERRIDE_IDENTIFIERS.LINKS]: () => resolvePayload([schema, convertPlatformLinksModel(value)])
            }[identifier] || (() => schema))()
        }, {}),
    registerToSiteMetadataChange: withActions((aspectActions, compId, comp) => {
        aspectActions.setMetadataChangedRegisteredComp(compId, comp)
    }),
    unRegisterToSiteMetadataChange: withActions((aspectActions, compId) => {
        aspectActions.setMetadataChangedRegisteredComp(compId, undefined)
    }),
    notify: (title, description = '', getRegComps) => {
        if (title) {
            _.forEach(getRegComps(), comp =>
                tpaHandlersFunctionLibrary.invokeComponentMethod(
                    comp, 'sendPostMessage', {
                        intent: 'addEventListener',
                        eventType: 'SITE_METADATA_CHANGED',
                        params: {title, description}
                    }))
        }
    },
    fetchTpaSeoData: withActions((
        {setTpaData, setFetchTpaDataStatus, setSeoReturnedStatusCode, pushToDebugInfo},
        {
            isPageAllowed, shouldFetch, shouldWait, fetch, baseUrl, metaSiteId, siteId, pageId,
            externalBaseUrl, mainPageId, pagesData, clientSpecMap, captureError, throwException,
            userAgent, parsedUrl, deviceType, routerData, tpaInnerRoute, canonicalUrl, tpaCompInfoArr,
            dateNumberFormat, language, isPrimaryLanguage, debugProps, pageTitle
        }) => {
        let fetchData = {}
        if (shouldFetch) {
            if (shouldWait) {
                return false
            }
            const url = `${baseUrl}?metaSiteId=${metaSiteId}`
            const data = {
                siteId,
                baseUri: externalBaseUrl,
                isRequestedUrlMigrated: true,
                isUrlMigrated: true,
                canonicalUrl,
                mainPageId,
                pageId,
                deepLink: '',
                userAgent,
                deviceType,
                routerData: JSON.stringify(routerData),
                tpaDataRequests: getTpaDataRequests({tpaCompInfoArr, clientSpecMap, captureError}),
                pagesData,
                dateNumberFormat,
                language,
                isPrimaryLanguage,
                pageTitle
            }
            if (parsedUrl.routerDefinition) {
                data.tpaInnerRoute = tpaInnerRoute
                data.canonicalUrl = sanitizeCanonical(data.canonicalUrl, tpaInnerRoute)
            } else {
                data.deepLink = tpaInnerRoute || ''
                data.canonicalUrl = sanitizeCanonical(data.canonicalUrl, data.deepLink)
            }
            // const overrideUrl = `http://api.84.wixprod.net/wix-public-html-seo-renderer-webapp/fetchApplicationsSeoData?metaSiteId=${metaSiteId}`
            setFetchTpaDataStatus(FETCH_TPA_DATA_STATUS.FETCHING)
            handleDebug(pushToDebugInfo, debugProps, {
                method: 'fetchData - onFetch',
                arguments: {
                    fetchRequestBody: data
                }
            })

            fetchData = {
                url,
                options: {
                    method: 'POST', body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        Accept: 'application/json; charset=UTF-8'
                    }
                },
                dataType: 'application/json',
                onSuccess: tpaData => {
                    try {
                        const tpaDataObject = JSON.parse(tpaData)
                        setTpaData(removeFalsyTpaSeoMetaData(tpaDataObject))
                        setSeoReturnedStatusCode(tpaDataObject.status)
                        handleDebug(pushToDebugInfo, debugProps, {
                            method: 'fetchData - onSuccess',
                            arguments: {
                                fetchRequestResponse: tpaDataObject
                            }
                        })
                    } catch (e) {
                        captureError(e)
                    }
                    setFetchTpaDataStatus(FETCH_TPA_DATA_STATUS.DONE)
                },
                onError: res => {
                    setFetchTpaDataStatus(FETCH_TPA_DATA_STATUS.DONE)
                    captureError('Failed to fetch TPA SEO Endpoint data.', res)
                    handleDebug(pushToDebugInfo, debugProps, {
                        method: 'fetchData - onError',
                        arguments: {
                            error: res
                        }
                    })
                    throwException(res)
                }
            }
        }
        if (!shouldFetch) {
            if (!isPageAllowed) {
                setSeoReturnedStatusCode(403)
            }
            return true
        }
        fetch(...Object.values(fetchData))
        return false
    }),
    getUserPattern: (userSeoPatternsArray = [], itemType) => {
        const userPattern = userSeoPatternsArray.find(pattern => pattern.patternType === itemType)
        const userPatternContent = _.get(userPattern, 'content', {})
        try {
            return JSON.parse(userPatternContent)
        } catch (e) {
            return {}
        }
    },
    getIndexableByUserPattern: userSeoPatterns => getValueByIdentifier(userSeoPatterns, EXTERNAL_IDENTIFIERS.ROBOTS),
    getIsOldBlog,
    getDataWithoutCorvidSchemas: withActions(({pushToDebugInfo}, data, corvidSchemas, currentPageId, debugProps) => {
        if (!data || !corvidSchemas || !currentPageId) {
            return resolvePayloadWithDebug({pushToDebugInfo}, data, debugProps)
        }
        let dataWithoutCorvidSchemas = resolvePayloadWithDebug({pushToDebugInfo}, data, debugProps)
        Object.keys(IDENTIFIER_TO_TAG_TYPE).forEach(identifier => {
            if (corvidSchemas[identifier] && corvidSchemas[identifier][currentPageId]) {
                dataWithoutCorvidSchemas = removeTagsByType(dataWithoutCorvidSchemas, IDENTIFIER_TO_TAG_TYPE[identifier])
            }
        })
        handleDebug(pushToDebugInfo, debugProps, {
            method: 'getDataWithoutCorvidSchemas',
            arguments: data, corvidSchemas, currentPageId,
            result: dataWithoutCorvidSchemas
        })
        return dataWithoutCorvidSchemas
    }),
    populateDebugInfoData: withActions(({pushToDebugInfo}, debugProps, resolvedData, context) => {
        if (!debugProps) {
            return
        }
        const data = {
            context,
            panelOverrides: mapPayload(resolvedData, {panelOverridesOnly: true}),
            patterns: mapPayload(resolvedData, {patternsOnly: true})
        }
        handleDebug(pushToDebugInfo, debugProps, data)
    }),
    setSsrData: withActions(({setSeoSsrData}, tags) => {
        setSeoSsrData(tags)
    }),
    buildOgImageFullUrl
}

function resolveSource(source) {
    return SOURCES[source] ? source : SOURCES.UNSPECIFIED
}

function sanitizeCanonical(canonical = '', path) {
    if (!path) {
        return canonical
    }
    const canonicalUrl = canonical.split('?')[0]
    const lastIndexOfDeepLink = canonicalUrl.lastIndexOf(path)
    const sanitizedCanonical = canonicalUrl.slice(0, lastIndexOfDeepLink) + canonicalUrl.slice(lastIndexOfDeepLink + path.length)
    return sanitizedCanonical.replace(/\/$/, '')
}

function set(identifier) {
    return function ({setOverride, setForceOverride, pushToDebugInfo}, currentPageId, debugProps, {value, source, forceOverride} = {}) {
        if (forceOverride) {
            setForceOverride(forceOverride)
        }
        const safeSource = resolveSource(source)
        setOverride(safeSource, identifier, currentPageId, value)
        handleDebug(pushToDebugInfo, debugProps, {
            method: 'set',
            arguments: {
                identifier, value, source, safeSource, forceOverride
            }
        })
        return value
    }
}

function _setRunTimeSchema({setOverride, pushToDebugInfo}, overrides, currentPageId, debugProps, {value = [], source} = {}) {
    const safeSource = resolveSource(source)
    const override = _.get(overrides, [safeSource, OVERRIDE_IDENTIFIERS.STRUCTURED_DATA, currentPageId], [])
    if (!Array.isArray(value)) {
        value = [value]
    }

    return set(OVERRIDE_IDENTIFIERS.STRUCTURED_DATA)({setOverride, pushToDebugInfo}, currentPageId, debugProps, {value: [...override, ...value], source: safeSource})
}

function getTpaDataRequests({tpaCompInfoArr, clientSpecMap, captureError}) {
    return _.map(tpaCompInfoArr, compInfo => {
        const dataNode = _.pick(compInfo.data, ['id', 'applicationId', 'type', 'widgetId', 'referenceId'])
        if (!dataNode.referenceId) {
            const verticalsReferenceId = getVerticalsReferenceId(clientSpecMap, compInfo, captureError)
            if (verticalsReferenceId) {
                dataNode.referenceId = verticalsReferenceId
            }
        }
        const component = _.pick(compInfo, ['id', 'type'])
        return {component, dataNode}
    })
}

function getVerticalsReferenceId(clientSpecMap, compInfo, captureError) {
    const applicationId = _.get(compInfo, 'data.applicationId')
    const appDefinitionName = _.get(clientSpecMap, [applicationId, 'appDefinitionName'])

    if (appDefinitionName === 'Wix Video') {
        const channelId = _.get(compInfo, 'style.style.properties.param_font_channelId')
        if (channelId) {
            try {
                return channelId.includes('-') ? JSON.parse(channelId) :
                JSON.parse(channelId).replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5')
            } catch (e) {
                captureError(e)
            }
        } else {
            return 'a1ca9dac-7ee4-4d52-a418-68329471105b'
        }
    }

    if (appDefinitionName === 'Wix Music') {
        const albumId = _.get(compInfo, 'style.style.properties.param_font_wmp::albumId')
        if (albumId) {
            try {
                return JSON.parse(albumId)[compInfo.style.style.propertiesSource['param_font_wmp::albumId']]
            } catch (e) {
                captureError(e)
            }
        } else {
            return '03a8f97f-c6ed-4f81-9fce-2a1c8086211b' // TODO @santa-ssr ask @peterk what's the default externalId cause this one is wrong
        }
    }

    return null
}

function removeFalsyTpaSeoMetaData(tpaData) {
    return _.flow([
        _getOr({}, 'applicationsSeoData'),
        _mapValues(tpa => ({
            ...tpa,
            compData: {
                ...tpa.compData,
                meta: _.pickBy(tpa.compData && tpa.compData.meta, _.identity)}
        }))
    ])(tpaData)
}

function getIsOldBlog(currentUrl = '') {
    return currentUrl.includes('/single-post/')
}

function mapPayload(payload, {panelOverridesOnly, patternsOnly} = {}) {
    const mappedPatterns = [
        {defaultStaticPatternSchema: payload[2]},
        {defaultItemTypePatternSchema: payload[3]},
        {userPatternSchema: payload[4]}
    ]

    if (patternsOnly) {
        return mappedPatterns
    }

    const mappedPanelOverrides = [
        {editorPanelLegacyData: payload[5]},
        {editorPanelData: payload[6]},
        {localizedEditorPanelData: payload[7]}
    ]

    if (panelOverridesOnly) {
        return mappedPanelOverrides
    }

    const mappedPayload = [
        {siteLevelData: payload[0]},
        {unspecifiedSchemaOverrides: payload[1]},
        ...mappedPatterns,
        ...mappedPanelOverrides,
        {dynamicPageData: payload[8]},
        {tpaData: payload[9]},
        {schemasFromTPAOverrides: payload[10]},
        {platformLegacySeoOverrides: payload[11]},
        {schemasFromCorvidOverrides: payload[12]}
    ].filter(payloadItem => _.get(Object.values(payloadItem)[0], 'tags.length', 0) > 0)

    return mappedPayload
}

function transformWixImageToPublicURL(wixImageUri) {
    const parsedUri = mediaItemUtils.parseMediaItemUri(wixImageUri)

    if (!parsedUri.error) {
        return imageClientSDK.getScaleToFillImageURL(
            parsedUri.mediaId,
            parsedUri.width,
            parsedUri.height,
            parsedUri.width,
            parsedUri.height,
            {name: parsedUri.title}
        )
    }
    return null
}

function resolvePlatformMetaTagsModel(tags) {
    const resolvedPlatformMetaTags = tags.map(tag => {
        const isWixImage = _.chain(tag)
        .get('content')
        .startsWith('wix:image')
        .value()

        return isWixImage ? {...tag, content: transformWixImageToPublicURL(tag.content)} : tag
    })
    return convertPlatformMetaTagsModel(resolvedPlatformMetaTags)
}
