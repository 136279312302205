import _ from 'lodash'
import {objectUtils, urlUtils} from 'santa-core-utils'
import {getSiteDataDestination} from '../../../utils/wixappsDataHandler'
import {LISTS_EVENTS, LIST_ERRORS} from '../../../utils/appPartBiConstants'

const getViewId = (type, name, format) => _.compact([type, name, format]).join('|')

/**
 * Convert the AppRpo that return from the server to the one we work with in the client.
 * @param repo
 * @returns {AppRepoDefinition}
 */
function transformAppRepo(repo) {
    repo = ensureRepoFields(repo)
    const views = _.transform(repo.views, (result, viewDef) => {
        const id = getViewId(viewDef.forType, viewDef.name, viewDef.format)
        result[id] = viewDef
    }, {})
    const dataSelectors = _.transform(repo.dataSelectors, (result, dataSelectorDef) => {
        result[dataSelectorDef.id] = dataSelectorDef
    }, {})
    const types = _.transform(repo.types, (result, typeDef) => {
        result[typeDef.name] = typeDef
    }, {})

    return _.defaults({
        views,
        dataSelectors,
        types,
        offsetFromServerTime: new Date() - new Date(repo.serverTime)
    }, repo)
}

/**
 *  performs validations on the returned repo and do stuff accordingly (such as fixing and report to BI)
 * @param listBuilderDrcAPI
 * @param repo
 */
function validateAppRepoAndFix({wixappsLogger, allAppPartNames}, repo) {
    repo.views = _.reduce(allAppPartNames, (views, partName) => {
        const part = repo.parts[partName]
        if (!part) {
            return views
        }

        const dataSelectorId = part.dataSelector || part.dataSelectorDef && part.dataSelectorDef.id // eslint-disable-line no-mixed-operators
        if (!repo.dataSelectors[dataSelectorId]) {
            wixappsLogger.reportEvent(LISTS_EVENTS.APP_PART2_FAILED_TO_LOAD_DATA_SELECTOR,
                {dataSelector: part.dataSelector})
        }

        if (!_.has(repo.types, part.type)) {
            // the type doesn't exist, so we can't generate a matching view
            return views
        }

        const possibleViews = [
            {forType: 'Array', name: part.viewName},
            {forType: 'Array', name: part.viewName, format: 'Mobile'},
            {forType: part.type, name: part.viewName},
            {forType: part.type, name: part.viewName, format: 'Mobile'}
        ]

        _.forEach(possibleViews, possibleView => {
            const viewId = _.compact([possibleView.forType, possibleView.name, possibleView.format]).join('|')

            if (repo.views[viewId]) {
                // no need to generate a view
                views[viewId] = repo.views[viewId]
                return
            }

            wixappsLogger.captureError(new Error('reached a list builder viewId in viewer that does not exist in repo views'))
        })

        return views
    }, {})

    _.forEach(repo.dataSelectors, dataSelector => {
        if (dataSelector.itemIds && dataSelector.itemIds.length) {
            const newItemIds = _.compact(dataSelector.itemIds)
            if (newItemIds.length !== dataSelector.itemIds.length) {
                wixappsLogger.reportError(LIST_ERRORS.DATA_SELECTOR_CONTAINS_NULL)
                dataSelector.itemsIds = newItemIds
            }
        }
        if (dataSelector.hiddenItemIds && dataSelector.hiddenItemIds.length) {
            dataSelector.itemIds = _.difference(dataSelector.itemIds, dataSelector.hiddenItemIds)
        }
    })

    _.forEach(repo.views, view => {
        const badStylesheets = objectUtils.filter(view, o => _.isPlainObject(o) &&
            _.has(o, 'color') &&
            _.has(o.color, '$expr') &&
            /Theme\.getColor\([^)]*/.test(o.color.$expr))
        _.forEach(badStylesheets, stylesheet => {
            delete stylesheet.color
        })
    })
}

function ensureRepoFields(repo) {
    repo = repo || {}
    repo.views = repo.views || {}
    repo.types = repo.types || {}
    repo.dataSelectors = repo.dataSelectors || {}
    repo.parts = repo.parts || {}
    return repo
}

function transformBundledPartsData({wixappsLogger, allAppPartNames}, responseData, currentValue) {
    if (!responseData.success) {
        return {}
    }
    const resData = responseData.payload

    if (!resData.repo && resData.blob) {
        resData.repo = JSON.parse(resData.blob)
    }
    resData.repo = transformAppRepo(resData.repo)

    _.forEach(resData.repo, (repo, field) => {
        objectUtils.ensurePath(currentValue, ['descriptor', field])
        _.forEach(repo, (value, key) => {
            objectUtils.setInPath(currentValue, ['descriptor', field, key], value)
        })
    })

    objectUtils.ensurePath(currentValue, ['descriptor', 'types'])
    _.forEach(resData.types, type => {
        objectUtils.setInPath(currentValue, ['descriptor', 'types', type.name], type)
        objectUtils.ensurePath(currentValue, ['items', type.name])
    })

    objectUtils.ensurePath(currentValue, ['descriptor', 'tags'])
    _.forEach(resData.tags, (value, key) => {
        objectUtils.setInPath(currentValue, ['descriptor', 'tags', key], value)
    })

    objectUtils.setInPath(currentValue, ['descriptor', 'applicationInstanceVersion'], resData.version)

    _.forEach(resData.items, item => {
        objectUtils.setInPath(currentValue, ['items', item._type, item._iid], item)
    })

    validateAppRepoAndFix({wixappsLogger, allAppPartNames}, currentValue.descriptor)

    return currentValue
}

function getBundledPartsDataRequest(listBuilderDrcAPI, appService, appPartsToFetch) {
    const {
        currentFullUrl,
        parseCurrentFullUrl,
        wixappsLogger,
        allAppPartNames
    } = listBuilderDrcAPI
    const partNames = [...appPartsToFetch]
    const pageAdditionalData = _.get(parseCurrentFullUrl(), 'pageAdditionalData')
    const destination = getSiteDataDestination(appService.type)

    return {
        url: `${urlUtils.baseUrl(currentFullUrl)}/apps/appBuilder/1/viewer/GetAppPartData`,
        destination,
        name: appService.type,
        data: {
            applicationInstanceId: appService.instanceId,
            appPartIds: partNames,
            itemIds: pageAdditionalData ? [pageAdditionalData] : []
        },
        transformFunc: (responseData, currentValue) =>
            transformBundledPartsData({wixappsLogger, allAppPartNames}, responseData, currentValue)
    }
}

export {
    getBundledPartsDataRequest
}
