const thirdPartyAnalytics = require('thirdPartyAnalytics') //eslint-disable-line import/no-unresolved
const _ = require('lodash')

function getDefaultChannels(props, promoteAnalyticsAdapter) {
    const options = {
        reportType: 'event',
        adapter: 'pa',
        sampleRatio: 0
    }
    return [{
        // Sends events to frog.wix.com
        name: promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
        report: thirdPartyAnalytics.decorateReporter(
            props,
            promoteAnalyticsAdapter.channelNames.BI_ANALYTICS,
            params => props.reportBI(options, params)
        )
    }]
}

function getEmbeddedChannels(props) {
    if (typeof window === 'undefined' || !window.promoteAnalyticsChannels) {
        return []
    }

    const embeddedChannels = window.promoteAnalyticsChannels.map(channel => ({
        name: channel.name,
        events: channel.events,
        report: thirdPartyAnalytics.decorateReporter(props, channel.name, channel.report),
        config: channel.config
    }))
    window.promoteAnalyticsChannels = undefined
    return embeddedChannels
}

function getManagedChannels(props, promoteAnalyticsAdapter) {
    const accountNameToChannelData = {
        facebookRemarketing: {
            name: promoteAnalyticsAdapter.channelNames.FACEBOOK_PIXEL,
            report: (eventType, eventName, data) => thirdPartyAnalytics.fireFacebookCustomEvent(props, eventType, eventName, data)
        },
        googleAnalytics: {
            name: promoteAnalyticsAdapter.channelNames.GOOGLE_ANALYTICS,
            report() {
                return thirdPartyAnalytics.reportGoogleAnalytics(props, ...arguments) //eslint-disable-line fp/no-arguments
            }
        },
        googleTagManager: {
            name: promoteAnalyticsAdapter.channelNames.GOOGLE_TAG_MANAGER,
            report: events => thirdPartyAnalytics.reportGoogleTagManager(props, events)
        },
        yandexMetrika: {
            name: promoteAnalyticsAdapter.channelNames.YANDEX_METRICA,
            report: () => thirdPartyAnalytics.reportYandexPageHit(props.currentUrl.full)
        }
    }

    return _.filter(accountNameToChannelData,
        (channelData, accountName) => thirdPartyAnalytics.hasAccount(props, accountName))
}

const initGoogleAnalytics = setIsGoogleAnalyticsInitialized => {
    const isGoogleAnalyticsObjectReady = typeof window.ga === 'function'
    if (isGoogleAnalyticsObjectReady) {
        window.ga('require', 'ec')
        setIsGoogleAnalyticsInitialized(true)
    }
}

module.exports = (props, eventName, params, options, promoteAnalyticsAdapter, {setIsAdapterInitialized, setIsGoogleAnalyticsInitialized}) => {
    if (!promoteAnalyticsAdapter.api) {
        return
    }
    if (!props.isAdapterInitialized || window.promoteAnalyticsChannels) {
        const channels = [
            ...getDefaultChannels(props, promoteAnalyticsAdapter),
            ...getManagedChannels(props, promoteAnalyticsAdapter),
            ...getEmbeddedChannels(props)
        ]
        promoteAnalyticsAdapter.api.init(channels)
        promoteAnalyticsAdapter.api.addListener([promoteAnalyticsAdapter.listeners[promoteAnalyticsAdapter.channelNames.WIX_DEVELOPERS_ANALYTICS]], props)
        setIsAdapterInitialized(true)
    }
    if (props.googleAnalytics && !props.isGoogleAnalyticsInitialized) {
        initGoogleAnalytics(setIsGoogleAnalyticsInitialized)
    }

    promoteAnalyticsAdapter.api.trackEvent(eventName, params, options)
}
