import {withActions} from 'carmi-host-extensions'
import _ from 'lodash'

const name = 'ConsentAspect'

export const defaultModel = {
    consentPolicyRegisteredComps: {}
}

export function init({}, {eventsManager, initialData: {
    sendPlatformConsentUpdates,
    windowObject,
    updatePolicy
}}) {
    eventsManager.on('consentPolicyChanged', event => {
        const policyDetails = event.detail
        const updates = {policyDetails}
        if (_.has(windowObject, ['consentPolicyManager', '_getConsentPolicyHeader'])) {
            updates.policyHeaderObject = windowObject.consentPolicyManager._getConsentPolicyHeader()
        }
        sendPlatformConsentUpdates(updates)
        updatePolicy(policyDetails)
    })
}

const functionLibrary = {
    registerToConsentPolicyUpdated: withActions((aspectActions, compId, comp) => {
        aspectActions.setConsentPolicyRegisteredComp(compId, comp)
    }),
    unRegisterToConsentPolicyUpdated: withActions((aspectActions, compId) => {
        aspectActions.setConsentPolicyRegisteredComp(compId, undefined)
    }),
    updateConsentPolicy: withActions((aspectActions, registeredComps, newPolicy) => {
        _.forEach(registeredComps, comp => {
            if (_.isFunction(comp.sendPostMessage)) {
                comp.sendPostMessage({
                    intent: 'addEventListener',
                    eventType: 'CONSENT_POLICY_UPDATE',
                    params: newPolicy
                })
            }
        })
    }),
    resetPolicy: windowObject => {
        if (windowObject && windowObject.consentPolicyManager) {
            windowObject.consentPolicyManager.resetPolicy()
        }
    },
    setConsentPolicy: (windowObject, defaultPolicyDetails, policy) => {
        if (windowObject && windowObject.consentPolicyManager) {
            return new Promise((resolve, reject) => {
                windowObject.consentPolicyManager.setConsentPolicy(policy, resolve, reject)
            })
        }

        return Promise.resolve(defaultPolicyDetails)
    }
}
export {name, functionLibrary}
