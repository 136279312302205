const name = 'WixEmbedsAPIAspect'
const EventEmitter = require('events')

const embedsEventsManager = new EventEmitter()

export function init({}, {initialData: {
    windowObject,
    modelConstants,
    isInSSR,
    getAppToken,
    shouldDispatchReadyEvent
  }}) {
    if (!!isInSSR || !windowObject) {
      return
    }
    const registerToEvent = (eventName, callback) => {
      embedsEventsManager.addListener(eventName, callback)
    }
    const unregisterFromEvent = (eventName, callback) => {embedsEventsManager.removeListener(eventName, callback)}

    const {externalBaseUrl, metasiteId, htmlsiteId, isWixSite, language} = modelConstants
    windowObject.wixEmbedsAPI = { 
      getExternalBaseUrl: () => externalBaseUrl, 
      getMetaSiteId: () => metasiteId,
      getHtmlSiteId: () => htmlsiteId,
      getLanguage: () => language,
      isWixSite: () => isWixSite,
      registerToEvent, 
      unregisterFromEvent,
      getAppToken
    }

    if (shouldDispatchReadyEvent) {
      dispatchReadyEvent(windowObject)
    }
  }

  function dispatchReadyEvent(windowObject) {
    if (!windowObject.document.documentMode) { //not ie11
      windowObject.dispatchEvent(new CustomEvent('wixEmbedsAPIReady'))	
    } else {
      const evt = windowObject.document.createEvent('CustomEvent')
      evt.initCustomEvent(
        'wixEmbedsAPIReady',
        true,
        false,
        {}
      )
      windowObject.dispatchEvent(evt) 
    }
  }

  function onUrlChanged(currentUrlPageId, pageTitle, windowObject) {
    if (windowObject) {
      embedsEventsManager.emit('pageNavigation', {name: pageTitle, id: currentUrlPageId})
    }   
  }

const functionLibrary = {
  getAppToken: (clientSpecMap, appDefinitionId) => (Object.values(clientSpecMap).find(item => item.appDefinitionId === appDefinitionId) || {}).instance,
  onUrlChanged
}

export {name, functionLibrary}