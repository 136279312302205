import _ from 'lodash'
import {withActions} from '../withActions'

const name = 'TimelineAspect'
const defaultModel = {
    timelines: {},
    timelineQueues: {}
}
const queueActions = {
    CREATE: 'create',
    ADD: 'add',
    PLAY: 'play',
    PLAY_REVERSE: 'playReverse',
    PAUSE: 'pause',
    SEEK: 'seek',
    CLEAR: 'clear',
    EVENT_ADD: 'add_event',
    EVENT_REMOVE: 'remove_event'
}
const TIMELINE_ANIMATION = 'TimelineAnimation'
const NO_TIMELINE_ERROR = 'Timeline not initiated'
const NO_ELEMENTS_ERROR = 'Timeline has no elements'
const CLEAR_PROPS = 'clip,clipPath,webkitClipPath,willChange,opacity,transform,transformOrigin'

const functionLibrary = {
    addActionToQueue: withActions(({addActionToTimelineQueue}, id, action, data = {}) =>
        new Promise((resolve, reject) => {
            addActionToTimelineQueue(id, {id, action, data, resolve, reject})
        })
    ),
    addCreateActionToQueue: withActions(({setCreateTimelineActionToQueue}, id, data = {}) =>
        new Promise((resolve, reject) => {
            setCreateTimelineActionToQueue(id, {id, data, resolve, reject})
        })
    ),
    removeTimeline: withActions(({setTimeline}, id, animator, sequence, elements) => {
        animator.kill(sequence.get(), 0)
        if (elements.length) {
            animator.animate('BaseClear', elements, 0, 0, {props: CLEAR_PROPS, immediateRender: false})
        }
        setTimeline(id)
        return null
    }),
    createTimeline: withActions(({setTimelineSequence, setCreateTimelineActionToQueue}, id, animator, {data, resolve, reject}) => {
        const {params} = data
        const sequence = animator.sequence({...params, data: {...params.data = {}, id}})

        if (!sequence) {
            reject(`Timeline create: ${NO_TIMELINE_ERROR}`)
            return true
        }

        setTimelineSequence(id, sequence) // Add to timelines
        setCreateTimelineActionToQueue(id) // remove from queue

        resolve({id})
        return true
    }),
    addToTimeline: withActions(({addTimelineCompId}, animator, sequence, elements, id, data, resolve, reject) => {
        const {compIds, params, offset} = data

        if (!sequence) {
            reject(`Timeline add: ${NO_TIMELINE_ERROR}`)
            return true
        }

        if (_.isEmpty(elements)) {
            reject(`Timeline add: ${NO_ELEMENTS_ERROR}`)
            return true
        }


        const animateSingle = ({duration = 0, delay = 0, ...animationParams}) => animator.animate(
            TIMELINE_ANIMATION,
            elements,
            duration,
            delay,
            animationParams
        )

        compIds.forEach(compId => addTimelineCompId(id, compId))
        sequence.add(params.map(animateSingle), offset)

        resolve()
        return true
    }),
    playTimeline: (sequence, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline play: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.play()

        resolve()
        return true
    },
    playReverseTimeline: (sequence, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline play reverse: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.reverse()

        resolve()
        return true
    },
    pauseTimeline: (sequence, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline pause: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.pause()

        resolve()
        return true
    },
    clearTimeline: withActions(({setTimelineCompIds}, sequence, id, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline pause: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.clear()
        setTimelineCompIds(id)

        resolve()
        return true
    }),
    seekTimeline: (sequence, progress, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline seek: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.seek(progress)

        resolve()
        return true
    },
    /**
     *
     * @param {Sequence} sequence
     * @param {'onComplete'|'onUpdate'|'onStart'|'onReverseComplete'|'onRepeat'} eventName
     * @param {function} handler
     * @param {Promise} resolve
     * @param {Promise} reject
     */
    addEventCallback: (sequence, {eventName, handler}, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline add event: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.event(eventName, handler)

        resolve()
        return true
    },
    /**
     *
     * @param {Sequence} sequence
     * @param {'onComplete'|'onUpdate'|'onStart'|'onReverseComplete'|'onRepeat'} eventName
     * @param {Promise} resolve
     * @param {Promise} reject
     */
    removeEventCallback: (sequence, {eventName}, resolve, reject) => {
        if (!sequence) {
            reject(`Timeline remove event: ${NO_TIMELINE_ERROR}`)
            return true
        }

        sequence.event(eventName, null)

        resolve()
        return true
    }
}

export {
    name,
    defaultModel,
    functionLibrary,
    queueActions
}
