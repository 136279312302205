import _ from 'lodash'
import {DIALOGS, NOTIFICATIONS} from '../constants'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {serializeFetchData} from './fetch-utils'

const createCallbackAndPromiseIfNoCallback = callback => {
    if (!callback) {
        let resolveForCallback
        let rejectForCallback
        const promise = new Promise((resolve, reject) => {
            resolveForCallback = resolve
            rejectForCallback = reject
        })
        callback = (result, error) => {
            if (error) {
                rejectForCallback(error)
            } else {
                resolveForCallback(result)
            }
        }
        callback.promise = promise
    }

    return callback
}

export default {
    performFetch: ({fetch, siteRevision, asForm, authorization, allowErrors}, url, data, onSuccess = _.noop, onError = _.noop) => {
        const headers = {
            ...authorization ? {authorization} : {},
            ...siteRevision ? {
                'x-wix-site-revision': siteRevision,
                'x-requested-with': 'XMLHttpRequest'
            } : {}
        }

        data = data || {}
        data.credentials = 'same-origin'

        fetch(url, serializeFetchData(data, asForm, headers, allowErrors), 'json', response => {
            if (response && response.errorCode) {
                onError(response.errorCode)
            } else {
                onSuccess(response)
            }
        }, response => response.json().then(({error, message}) => onError(error || message)))
    },
    showAuthenticationDialog: withActions(({setDialogOptions}, {isTemplate, smSettings, dialogOptions}, options) => {
        if (isTemplate) {
            setDialogOptions({
                dialogType: DIALOGS.Notification,
                notificationType: NOTIFICATIONS.Template,
                language: options.language
            })
        } else {
            const isLogin = _.isBoolean(options.showLoginDialog) ? options.showLoginDialog : smSettings && smSettings.smFirstDialogLogin
            const dialogType = isLogin ? DIALOGS.Login : DIALOGS.SignUp
            setDialogOptions({...dialogOptions || {}, ...options, dialogType})
        }
    }),
    logout: withActions(({setDialogOptions}, {doLogout, reloadPage, isOwner}, languageToDisplay, onSuccess, onError, options) => {
        if (isOwner) {
            setDialogOptions({
                notificationType: NOTIFICATIONS.SiteOwner,
                dialogType: DIALOGS.Notification,
                languageToDisplay
            })
            onError('Current member is the site owner, which can not be logout')
        } else {
            doLogout(options && options.navigateToPage || reloadPage, onError)
        }
    }),
    showEmailVerificationDialog: withActions(({setDialogOptions}, {language}, {emailVerified, member, sessionToken}) => {
        const shouldShow = sessionToken === null && !emailVerified && member.status === 'ACTIVE'
        if (shouldShow) {
            setDialogOptions({
                pendingMemberId: member.id,
                dialogType: DIALOGS.EmailVerification,
                language
            })
        }
        return shouldShow
    }),
    showApplyForMembershipDialog: withActions(({setDialogOptions, setMemberDetails}, {language}, {email, memberStatus, memberData, onSuccess}) => {
        const shouldShow = memberStatus === 'APPLICANT'
        if (shouldShow) {
            if (_.isFunction(onSuccess)) {
                onSuccess(memberData)
            }
            setMemberDetails({email})
            setDialogOptions({
                dialogType: DIALOGS.Notification,
                notificationType: NOTIFICATIONS.SignUp,
                language
            })
        }
        return shouldShow
    }),
    applySessionToken: withActions(({setPageJsonFileName}, {navigateToPage, dialogOptions, authenticateSession, reloadClientSpecMap, resetNextPageNavigationInfo}, sessionToken, onError, onSuccess) => {
        const data = {
            method: 'POST',
            body: {token: sessionToken}
        }
        authenticateSession(data, ({payload: {pages}}) => {
            _.forEach(pages, (value, key) => setPageJsonFileName(key, value))
            reloadClientSpecMap(() => {
                onSuccess()
                resetNextPageNavigationInfo()
                if (dialogOptions) {
                    const nextPageId = dialogOptions.nextPageId
                    const nextNavigationInfo = dialogOptions.nextNavigationInfo
                    if (navigateToPage && nextPageId) {
                        navigateToPage(nextNavigationInfo ? nextNavigationInfo : {pageId: nextPageId})
                    }
                }
            })
        }, onError)
    }),
    handleLogin: withActions(({setMemberDetails}, {showEmailVerificationDialog, applySessionToken, loginUser, collectionId, metaSiteId, appUrl, svSession, interactionEnded}, loginData, onSuccess, onError) => {
        const {email, password} = loginData
        const data = {
            method: 'POST',
            body: {email, password, collectionId, metaSiteId, appUrl, svSession}
        }

        const successCb = dto => {
            setMemberDetails(dto.member)
            onSuccess(dto)
            interactionEnded()
        }

        loginUser(data, ({payload: {sessionToken, siteMemberDto: member}}) => {
            const emailVerified = member.emailVerified
            const pendingStatus = showEmailVerificationDialog({emailVerified, member, sessionToken})
            if (pendingStatus) {
                interactionEnded()
            } else if (sessionToken) {
                applySessionToken(sessionToken, onError, () => successCb({sessionToken, member}))
            }
        }, onError)
    }),
    getMemberDetails: withActions(({setMemberDetails}, {getMemberDetails, isLoggedIn, memberDetails, memberDetailsChangeRegisteredComps}, onSuccess = _.noop, onError = _.noop, refreshCurrentMember, isPreviewMode) => {
        if ((isLoggedIn || isPreviewMode) && memberDetails && !refreshCurrentMember) {
            onSuccess(memberDetails)
            return memberDetails
        } else if (isLoggedIn || isPreviewMode) {
            getMemberDetails(({payload: details}) => {
                setMemberDetails(details)
                if (refreshCurrentMember) {
                    _.forEach(memberDetailsChangeRegisteredComps, comp =>
                        comp.sendPostMessage({
                            intent: 'addEventListener',
                            eventType: 'MEMBER_DETAILS_UPDATED',
                            params: details
                        }))
                }

                if (onSuccess) {
                    onSuccess(details)
                }
            }, onError)
        }

        return null
    }),
    authorizeMemberPages: withActions(({setPageJsonFileName}, {getAuthorizedPages}, onSuccess = _.noop, onError = _.noop) => {
        getAuthorizedPages(({authorizedPages}) => {
            _.forEach(authorizedPages, (value, key) => setPageJsonFileName(key, value))
            onSuccess(Object.keys(authorizedPages))
        }, onError)
    }),
    registerToMemberDetailsChange: withActions(({setMemberDetailsChangeRegisteredComps}, comp) =>
        setMemberDetailsChangeRegisteredComps(comp.props.id, comp)),
    unRegisterMemberDetailsChange: withActions(({setMemberDetailsChangeRegisteredComps}, comp) =>
        setMemberDetailsChangeRegisteredComps(comp.props.id, null)),
    registerToUserLogin: withActions(({setMemberLoginCallback}, callback) => {
        const callbackId = _.uniqueId('callback')
        setMemberLoginCallback(callbackId, callback)
        return callbackId
    }),
    unRegisterToUserLogin: withActions(({setMemberLoginCallback}, callbackId) =>
        setMemberLoginCallback(callbackId, null)),
    authenticateSession: ({applySessionToken}, token, callback) =>
        applySessionToken(token, error => callback(error), () => callback()),
    loginUser: ({handleLogin, interactionStarted}, {email, password, callback}) => {
        interactionStarted()
        callback = createCallbackAndPromiseIfNoCallback(callback)
        const onSuccess = ({sessionToken}) => callback(sessionToken)
        const onError = error => callback(null, error)
        handleLogin({email, password}, onSuccess, onError)
        return callback.promise
    },
    registerUser: ({showEmailVerificationDialog, showApplyForMembershipDialog, applySessionToken, customRegister, interactionStarted, interactionEnded}, registerData, callback) => {
        interactionStarted()
        callback = createCallbackAndPromiseIfNoCallback(callback)
        const onError = error => callback(null, error)
        const data = {
            method: 'POST',
            body: registerData
        }

        customRegister(data, ({session, member, approvalToken}) => {
            const emailVerified = member.emailVerified
            const sessionToken = session && session.token || null
            if (showEmailVerificationDialog({emailVerified, member, sessionToken})) {
                callback({member})
                interactionEnded()
            } else if (registerData.defaultFlow && showApplyForMembershipDialog({memberStatus: member.status, email: member.loginEmail}) || !sessionToken) {
                callback({member, approvalToken})
                interactionEnded()
            } else {
                applySessionToken(sessionToken, onError, () => {
                    callback({session, member})
                    interactionEnded()
                })
            }
        }, onError)
        return callback.promise
    }
}
