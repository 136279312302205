'use strict'
const _ = require('lodash')
const constants = require('../../utils/constants')

const transformKeys = ['scale', 'rotate', 'translate', 'skew']

const isPropertyDefined = (property, styleItem) => styleItem.hasOwnProperty(property) && styleItem[property] !== undefined && styleItem[property] !== null

const omitUndefinedProperties = styleItem => _.pickBy(styleItem, (__, property) => isPropertyDefined(property, styleItem))

const getSelectorByVariant = variant => {
    switch (variant.type) {
        case 'Hover':
            return ':hover'
        case 'Class':
            return `.${variant.id}`
        default:
            // no matching type, throwing
            throw new Error('Variant condition does not exist', variant.type)
    }
}
const getAdditionalPrefixes = (prefixes, suffix) => {
    if (!prefixes || !prefixes.length) {
        return ''
    }
    return prefixes.map(prefix => `,${prefix}${suffix}`)
}

const getUnit = unit => {
    if (unit === 'percentage') {
        return '%'
    }
    return unit
}

const resolveValueWithUnit = valueWithUnit => valueWithUnit ? `${valueWithUnit.value}${getUnit(valueWithUnit.type)}` : 0

const resolveValue = value => value || 0

const isStyleItemContainsTransformProps = styleItem => transformKeys.some(property => isPropertyDefined(property, styleItem))

const applyCSSToChildren = (rule, strategy) =>
    strategy === constants.VARIANTS_CSS_STRATEGIES.ALL_CHILDREN ? `${rule}, ${rule} *` : `${rule}, ${rule} > *`

module.exports = {
    functionLibrary: {
        getCSSRules: (cssString, selectors, isComponentStyle, defaultPrefixes) => isComponentStyle ?
            // eslint-disable-next-line no-control-regex
            cssString.replace(new RegExp('([^\r\n,{}]+)(,(?=[^}]*{)|\s*{)', 'g'), rule => {
                let cssRule = rule.trim()
                const lastChar = cssRule.charAt(cssRule.length - 1)
                cssRule = lastChar === '{' || lastChar === ',' ? cssRule.slice(0, -1) : cssRule
                const res = `${selectors}${cssRule}, ${selectors} ${cssRule}${getAdditionalPrefixes(defaultPrefixes, `${cssRule}`)}${getAdditionalPrefixes(defaultPrefixes, ` ${cssRule}`)}`
                return `${res}${lastChar}`
            }) : `${selectors}${getAdditionalPrefixes(defaultPrefixes, '')} ${cssString}`,

        getCSSFromTransformationItem: (styleItem, componentDefaultRotation) => {
            const css = []
            const filteredStyleItem = omitUndefinedProperties(styleItem)
            if (filteredStyleItem.hasOwnProperty('hidden')) {
                css.push(`opacity: ${filteredStyleItem.hidden ? 0 : 1} `)
            }
            if (filteredStyleItem.origin) {
                css.push(`transform-origin: ${resolveValueWithUnit(filteredStyleItem.origin.x)} ${resolveValueWithUnit(filteredStyleItem.origin.y)} `)
            }
            if (filteredStyleItem.filter) {
                const {blur = 0, opacity = 100, grayscale = 0} = filteredStyleItem.filter
                css.push(`filter: opacity(${opacity}%) grayscale(${grayscale}%) blur(${blur}px) `)
            }
            if (isStyleItemContainsTransformProps(filteredStyleItem)) {
                let cssStr = Object.entries(filteredStyleItem)
                    .reduce((acc, pair) => {
                        switch (pair[0]) {
                            case 'scale':
                                const {x: scaleX, y: scaleY} = pair[1]
                                if (scaleX) {
                                    acc += `scaleX(${resolveValue(scaleX)}) `
                                }
                                if (scaleY) {
                                    acc += `scaleY(${resolveValue(scaleY)}) `
                                }
                                break
                            case 'translate':
                                const {x: translateX, y: translateY} = pair[1]
                                if (translateX) {
                                    acc += `translateX(${resolveValueWithUnit(translateX)}) `
                                }
                                if (translateY) {
                                    acc += `translateY(${resolveValueWithUnit(translateY)}) `
                                }
                                break
                            case 'skew':
                                const {x: skewX, y: skewY} = pair[1]
                                if (skewX) {
                                    acc += `skewX(${resolveValue(skewX)}deg) `
                                }
                                if (skewY) {
                                    acc += `skewY(${resolveValue(skewY)}deg) `
                                }
                                break
                        }
                        return acc
                    }, 'transform: ')
                if (filteredStyleItem.rotate || componentDefaultRotation) {
                    const compRotation = componentDefaultRotation || 0
                    const variantRotation = filteredStyleItem.rotate || 0
                    cssStr += `rotate(${resolveValue(variantRotation) + compRotation}deg) `
                }
                css.push(`${cssStr}`)
            }

            return css.length ? `{ ${css.join('; ')}}` : ''
        },
        getCSSFromTransitionItem: styleItem => {
            const {property = 'all', timingFunction = 'ease', duration = 0, delay = 0} = styleItem
            return `{ transition: ${property} ${duration}s ${timingFunction} ${delay}s }`
        },
        getSelectorsForStyleChanges: (variants, compId) => {
            let variantCompId = variants[0].componentId
            let selectors = variantCompId === compId ? `[id^="${variantCompId}"]` : `#${variantCompId}`
            let currentsSource = variants[0].componentId
            variants.forEach(variant => {
                variantCompId = variant.componentId
                const selector = getSelectorByVariant(variant)
                if (currentsSource !== variantCompId) {
                    if (variantCompId === compId) {
                        selectors += ` [id^="${variantCompId}"]`
                    } else {
                        selectors += ` #${variantCompId}`
                    }
                    currentsSource = variantCompId
                }
                selectors += selector
            })

            if (currentsSource !== compId) {
                selectors += ` [id^="${compId}"]`
            }
            return selectors
        },
        getSelectorsForEffects: (variants, compId, applyToChildrenStrategy) => {
            let currentsSource = variants[0].componentId
            let selectors = `#${currentsSource}`
            let repeaterSelectors = currentsSource === compId ? `[id^="${currentsSource}__"]` : `#${currentsSource}`
            variants.forEach(variant => {
                const variantCompId = variant.componentId
                const selector = getSelectorByVariant(variant)
                if (currentsSource !== variantCompId) {
                    if (variantCompId === compId) {
                        selectors += ` #${variantCompId}`
                        repeaterSelectors += ` [id^="${variantCompId}__"]`
                    } else {
                        selectors += ` #${variantCompId}`
                        repeaterSelectors += ` #${variantCompId}`
                    }
                    currentsSource = variantCompId
                }
                selectors += selector
                repeaterSelectors += selector
            })

            if (currentsSource !== compId) {
                selectors += ` #${compId}`
                repeaterSelectors += ` [id^="${compId}__"]`
            }
            selectors = applyToChildrenStrategy ? applyCSSToChildren(selectors, applyToChildrenStrategy) : selectors
            repeaterSelectors = applyToChildrenStrategy ? applyCSSToChildren(repeaterSelectors, applyToChildrenStrategy) : repeaterSelectors

            return `${selectors}, ${repeaterSelectors}`
        },
        applyRuleToChildren: (cssRule, applyToChildrenStrategy) => cssRule.split(',')
            .map(s => s.trim())
            .map(css => applyCSSToChildren(css, applyToChildrenStrategy))
            .join()
    }
}
