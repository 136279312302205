import {withActions} from 'carmi-host-extensions/src/aspects/withActions'
import {urlUtils} from 'santa-core-utils'
import _ from 'lodash'

export const name = 'ClientSpecMapAspect'
export const metaSiteApplicationId = '-666'


export const defaultModel = {
    appInstanceMap: {},
    instancesExpirationDate: '',
    instanceChangedRegisteredComps: {},
    reloadSpecMapPlugins: [],
    isDuringClientSpecMapReload: false,
    isAfterClientSpecMapReload: false,
    instanceTimeout: null
}

const getDynamicApiUrl = (siteBaseUrl = '', windowObj, urlParams = {}, isPreviewMode) => {
    if (isPreviewMode) {
        return '/_api/wix-html-editor-webapp/fetch-editor-client-spec-map'
    }
    let url = `${siteBaseUrl.replace(/\/$/, '')}/_api/dynamicmodel`
    const params = _.isEmpty(urlParams) ? '' : `?${urlUtils.toQueryString(urlParams)}`
    if (windowObj) {
        url = url.replace(/^[^:]+:/, windowObj.location.protocol)
    }
    return url + params
}

const wixBiSessionPropertiesToUpdateOnReload = ['visitorId', 'siteMemberId']
const sessionInfoPropertiesToUpdateOnReload = ['svSession', 'hs', 'ctToken']

const calcExpirationTimeInMs = expirationDateStr => {
    const expirationDateObj = new Date(expirationDateStr)
    const thirtyMinutes = 30 * 60 * 1000
    return expirationDateObj.getTime() - Date.now() - thirtyMinutes
}

export const functionLibrary = {
    getDynamicApiUrl,
    reloadClientSpecMap: withActions((aspectActions, {
        fetchFn,
        externalBaseUrl,
        windowObj,
        reloadSpecMapPlugins,
        updateClientSpecMap,
        updateWixBiSessionProperty,
        updateSessionInfoProperty,
        setIsDuringClientSpecMapReload,
        setIsAfterClientSpecMapReload
    }, callback = _.noop, config = {}) => {
        const onSuccess = response => {
            _.invokeMap(reloadSpecMapPlugins, 'callback')
            _.forEach(wixBiSessionPropertiesToUpdateOnReload, prop => {
                if (response[prop]) {
                    updateWixBiSessionProperty(prop, response[prop])
                }
            })
            _.forEach(sessionInfoPropertiesToUpdateOnReload, prop => {
                if (response[prop]) {
                    updateSessionInfoProperty(prop, response[prop])
                }
            })
            if (response.clientSpecMap) {
                aspectActions.setInstanceMap({})
                aspectActions.setInstancesExpirationDate('')
                updateClientSpecMap(response.clientSpecMap)
            }
            setIsAfterClientSpecMapReload(true)
            setIsDuringClientSpecMapReload(false)
            callback(response)
        }
        const url = getDynamicApiUrl(externalBaseUrl, windowObj, config.urlParams)
        setIsDuringClientSpecMapReload(true)
        fetchFn(url, {credentials: 'include'}, 'json', onSuccess, callback)
    }),
    generateNewAppInstance: withActions((aspectActions, {fetchFn, url}, applicationId, callback) => {
        const onSuccess = response => {
            const instance = _.get(response, ['clientSpecMap', applicationId, 'instance'])
            aspectActions.setAppInstance(applicationId, instance)
            callback(instance)
        }
        fetchFn(url, null, 'json', onSuccess, () => {
            callback()
        })
    }),
    expireInstances: withActions((aspectActions, expirationDateStr, {isTemplate, getAppInstanceMap, clientSpecMapInstance, fetchFn, url, isExperimentOpen, getInstanceTimeout}) => {
        const instanceTimeout = getInstanceTimeout()
        if (expirationDateStr && isExperimentOpen && !isTemplate) {
            const expirationTime = calcExpirationTimeInMs(expirationDateStr)
            if (instanceTimeout) {
                clearTimeout(instanceTimeout)
            }
            const timeout = setTimeout(() => {
                const metaSiteInstance = getAppInstanceMap()[metaSiteApplicationId] || clientSpecMapInstance
                fetchFn(url, {headers: {Authorization: metaSiteInstance}}, 'json', response => {
                    const clientSpecMap = _.get(response, ['clientSpecMap']) || response
                    if (clientSpecMap) {
                        const instanceMap = _(clientSpecMap)
                            .mapValues('instance')
                            .omitBy(_.isUndefined)
                            .value()
                        aspectActions.setInstanceMap(instanceMap)
                        aspectActions.setInstancesExpirationDate(_.get(clientSpecMap, [metaSiteApplicationId, 'expirationDate']))
                    }
                })
            }, expirationTime)
            aspectActions.setInstanceTimeout(timeout)
        }
    }),
    registerToInstanceChanged: withActions((aspectActions, instanceChangedRegisteredComps, comp) => {
        const applicationId = _.get(comp, 'props.compData.applicationId')
        const compId = _.get(comp, 'props.id')
        if (instanceChangedRegisteredComps[applicationId]) {
            aspectActions.setCompToInstanceChangedEvent(applicationId, compId, comp)
        } else {
            aspectActions.setAppInstanceChangedEvent(applicationId, {[compId]: comp})
        }
    }),
    unRegisterToInstanceChanged: withActions((aspectActions, instanceChangedRegisteredComps, comp) => {
        const applicationId = _.get(comp, 'props.compData.applicationId')
        const compId = _.get(comp, 'props.id')
        if (_.has(instanceChangedRegisteredComps, [applicationId, compId])) {
            aspectActions.setCompToInstanceChangedEvent(applicationId, compId, undefined)
        }
    }),
    registerReloadSpecMapPlugin: withActions((aspectActions, pluginsSize, getCurrentPrimaryPageIdFn, compPageId, callback) => {
        const plugin = {
            compPageId,
            callback: () => {
                const currentPageId = getCurrentPrimaryPageIdFn()
                if (currentPageId === compPageId) {
                    callback()
                }
            }
        }
        aspectActions.spliceReloadSpecMapPlugin(pluginsSize, 0, plugin)
    })
}
