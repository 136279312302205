import _ from 'lodash'
import * as platformizedEndpointsUtils from 'santa-platform-utils/dist/cjs/platformizedEndpointsUtils'
import {SOURCES} from '../../seo/seo.sources'
import {withActions} from 'carmi-host-extensions/src/aspects/withActions'

const urlRegex = /((https?\:)\/\/)?([^\?\:\/#]+)(\:([0-9]+))?(\/[^\?\#]*)?(\?([^#]*))?(#.*)?/i
const promisesMap = new WeakMap()
const {serializeContactInfo} = platformizedEndpointsUtils.contacts

const createPromiseForMessage = message => {
    if (!promisesMap.has(message)) {
        let callback
        let _resolve
        let _reject
        const promise = new Promise((resolve, reject) => {
            _resolve = resolve
            _reject = reject
            callback = (error, result, _transfer) => error ? reject(error) : resolve({result, _transfer})
        })
        promisesMap.set(message, {promise, callback, reject: _reject, resolve: _resolve})
    }
    return promisesMap.get(message)
}

export const functionLibrary = {
    isHttps: url => {
        const match = url.match(urlRegex)
        const protocol = match[2] || 'http:'
        return protocol.startsWith('https')
    },
    reject: val => {
        let _reject
        const result = new Promise((resolve, reject) => {
            _reject = reject
        })
        result.catch(() => {})
        _reject(val)
        return result
    },
    serializeContactInfo,
    fetchPromise: (fetch, url, options, dataType) => new Promise((resolve, reject) => {
        fetch(url, options, dataType, resolve, reject)
    }),
    crmApplySession: async ({updateSessionInfoProperty, reloadClientSpecMap}, response) => {
        if (!response.svSession) {
            return response
        }

        updateSessionInfoProperty('svSession', response.svSession)
        await new Promise(resolve => reloadClientSpecMap(resolve))
        return _.omit(response, 'svSession')
    },
    invokeWithInstanceAndCallback: (fn, instance, ...params) => fn && instance && new Promise((resolve, reject) => {
        fn.call(instance, ...params.concat((result, error) => {
            if (error === null) {
                resolve(result)
            } else {
                reject(error)
            }
        }))
    }),

    smRegisterWrapper: promise => promise.then(data => {
        const res = {
            status: data.member.status === 'ACTIVE' ? 'ACTIVE' : 'PENDING',
            memberData: _.omit(data.member, 'status')
        }
        if (data.approvalToken) {
            res.approvalToken = data.approvalToken
        }
        return res
    }),

    getRoutersSiteMap: (prefix, querySiteMap, getNavInfo, getRoutersByPrefix) => new Promise((resolve, reject) => {
        const routerByPrefix = getRoutersByPrefix()
        const routerDefinition = routerByPrefix[prefix]
        if (!routerDefinition) {
            reject('no such route')
            return
        }
        const navInfo = getNavInfo()
        querySiteMap(navInfo,
            wrappedResult => resolve(wrappedResult.result),
            reject
        )
    }),

    openLightbox: (navigate, registerCallback, pageId) => new Promise(resolve => {
        navigate()
        registerCallback(pageId, resolve)
    }),

    publishSavedPubSubEventToWorker: (api, appDefId, msgData, evt) => {
        if (!evt || _.isEmpty(evt.data)) {
            return
        }
        const data = {
            type: 'invoke_worker_subscribers',
            appDefId,
            data: {
                eventKey: msgData.eventKey,
                callbackId: msgData.callbackId,
                eventDataArray: evt.data,
                invokePastEvents: true
            }
        }
        api.sendMessage(data)
    },


    publishPubSubEventToWorker: (api, appDefId, evt) => {
        const data = {
            type: 'invoke_worker_subscribers',
            appDefId,
            data: {
                eventKey: evt.params.name,
                eventData: evt.params
            }
        }
        api.sendMessage(data)
    },
    getCurrentGeolocation: getCurrentPositionFn =>
        getCurrentPositionFn().then(position => ({
            timestamp: _.get(position, 'timestamp'),
            coords: _.toPlainObject(_.get(position, 'coords'))
        })),
    getUserData: (isLoggedIn, getMemberDetails) =>
        new Promise((resolve, reject) => {
            if (isLoggedIn) {
                getMemberDetails((currentUser, error) => {
                    if (error) {
                        reject(error)
                    } else {
                        resolve(currentUser)
                    }
                }, error => reject(error))
            } else {
                reject('No User is currently logged in')
            }
        }),
    extractUserData: (getUserData, {field, errorMsg}) =>
        getUserData.then(currentUser => {
            const data = _.isObject(currentUser) ? currentUser[field] : null
            if (data) {
                return data
            }
            throw errorMsg
        }),
    promiseForMessage: message => createPromiseForMessage(message),
    promiseChain: (...promises) => promises.reduce((prev, next) => prev.then(next), Promise.resolve()),
    swallowPromiseResult: (promise, errorValue) => promise.then(() => true).catch(err => {
        if (errorValue && err) {
            throw errorValue
        } else if (err) {
            throw err
        }
    }),
    serializeMemberRoles: promise => promise
        .then(data => platformizedEndpointsUtils.members.serializeMemberRoles(data)),
    isEmpty: _.isEmpty,
    updateSeo: (setters, message, siteName, pageId) => {
        const title = _.get(message, ['data', 'title', 'text'])
        if (title) {
            setters.setCompData(pageId, {title})
        }
        setters.setRunTimePageTitle({
            value:
                _.get(message, ['data', 'title', 'addSiteName'], true) && siteName ?
                    `${title} | ${siteName}` :
                    title,
            source: SOURCES.TPA
        })
        setters.setRunTimePageDescription({
            value: _.get(message, ['data', 'description']),
            source: SOURCES.TPA
        })
        setters.setRunTimeSchema({
            value: _.get(message, ['data', 'jsonLd']),
            source: SOURCES.TPA
        })
        setters.setRunTimeMetaTags({
            value: _.get(message, ['data', 'metaTags']),
            source: SOURCES.TPA
        })
    },
    setResourceToPrefetch: withActions((actions, urls) => {
        urls.forEach(url => {
            actions.setResourceToPrefetch(0, 0, url)
        })
    }),
    copyToClipboard: (windowObj, message, showModalFn, addMessageToModalMessageQueue) => new Promise(resolve => {
        const textToCopy = _.get(message, 'data.text')
        const {document} = windowObj

        const tmp = document.createElement('input')
        tmp.value = textToCopy
        tmp.style.height = '0'
        tmp.style.overflow = 'hidden'
        tmp.style.border = 'none'
        document.body.appendChild(tmp)
        tmp.select()
        // https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
        tmp.setSelectionRange(0, 99999) /*For mobile devices*/
        const copySucceeded = document.execCommand('copy')
        document.body.removeChild(tmp)

        if (copySucceeded) {
            resolve({})
        } else {
            const modalId = showModalFn(resolve)
            addMessageToModalMessageQueue(modalId, {
                data: {
                    textToCopy,
                    /* TODO this flag was added to allow backwards compatibility with ecom due to issue PLAT-211 (see implementation in santa tpaCompApiMixin) */
                    waitForIFrameToLoad: true
                }
            })
        }
    }),
    addQueryParams: (addedQueryParams, currentNavigationInfo, navigateToPageFn) => {
        const newNavigationInfo = _.cloneDeep(currentNavigationInfo)
        delete newNavigationInfo.anchorData
        _.merge(newNavigationInfo, {queryParams: addedQueryParams})
        _.set(newNavigationInfo, 'shouldDisableScrollToTop', true)
        _.set(newNavigationInfo, 'skipHistory', false) // allow history update after pressing the browser's back button
        navigateToPageFn(newNavigationInfo)
    },
    removeQueryParams: (removedKeys, currentNavigationInfo, navigateToPageFn) => {
        const newNavigationInfo = _.cloneDeep(currentNavigationInfo)
        delete newNavigationInfo.anchorData
        _.set(newNavigationInfo, 'queryParams', _.omit(newNavigationInfo.queryParams, removedKeys))
        _.set(newNavigationInfo, 'shouldDisableScrollToTop', true)
        _.set(newNavigationInfo, 'skipHistory', false) // allow history update after pressing the browser's back button
        navigateToPageFn(newNavigationInfo)
    }
}
